import React, { useEffect, useState } from 'react';

import { ButtonGroup, Button } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix

import { useDispatch, useSelector } from 'react-redux';
import { getAprByLevel,getAprByStake, getRewardsByHour,setAprChartType,setWorkerChartTitle } from '../../phalaSlice';

const AprByLevel = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [Level1Array, setLevel1] = useState([]);
  const [Level2Array, setLevel2] = useState([]);
  const [Level3Array, setLevel3] = useState([]);
  const [Level4Array, setLevel4] = useState([]);
  const [Level5Array, setLevel5] = useState([]);
  const [AvgArray, setAvg] = useState([]);

  const [titleText, setTitle] = useState('24h');
  const [chartInterval, setChartInterval] = useState('24h');
  

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { aprByLevel,aprByLevelIsLoading,workerChartTitle } = useSelector(store => store.phala);
  const avgAprLvlWidget = aprByLevel.reduce((acc, cur) => acc + cur.averageApr, 0) / AvgArray.length;
  const level1AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level1, 0) / Level1Array.length;
  const level2AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level2, 0) / Level2Array.length;
  const level3AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level3, 0) / Level3Array.length;
  const level4AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level4, 0) / Level4Array.length;
  const level5AprWidget = aprByLevel.reduce((acc, cur) => acc + cur.level5, 0) / Level5Array.length;

  const setAprChartHandler = (chartType) => {
    dispatch(setAprChartType(chartType));
  };

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'bottom', // set the tooltip position to top
      offset: [300, 300], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
    }
    },
    toolbox: {
      feature: {
        
      },
      iconStyle: {
        borderColor: 'white',
      }
    },

    legend: {
      data: ['Average', 'Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'],
      top: '25',
      inactiveColor: '#555555',
      
      top:'auto',
      selected: {
        'Level 1': false,
        'Level 2': false,
        'Level 3': false,
        'Level 4': false,
        'Level 5': false,
        'Average': true,
      },
      textStyle: {
        color: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5  // set the opacity of the axis labels to 50%
      },
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
            opacity: 0.08  // set the opacity of the line to 50%
        }
    },
      axisLabel: {
            opacity: 0.5  // set the opacity of the axis labels to 50%
    },
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    series: [
      {
        name: 'Level 1',
        data: Level1Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#5C3ABD'
      },
      {
        name: 'Level 2',
        data: Level2Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#4FB3BF'
      },
      {
        name: 'Level 3',
        data: Level3Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#D1BE65'
      },
      {
        name: 'Level 4',
        data: Level4Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color:'#E88834'
      },
      {
        name: 'Level 5',
        data: Level5Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color:'#AD5CBD'
      },
      {
        name: 'Average',
        data: AvgArray,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#4DBD74'
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let l1 = [];
    let l2 = [];
    let l3 = [];
    let l4 = [];
    let l5 = [];
    let avg = [];
    let tcat = [];
    for (let m of aprByLevel) {
      l1.push(parseInt(m.level1));
      l2.push(parseInt(m.level2));
      l3.push(parseInt(m.level3));
      l4.push(parseInt(m.level4));
      l5.push(parseInt(m.level5));
      avg.push(parseInt(m.averageApr));
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === "hour"){
        const month = String(dt.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const day = String(dt.getDate()).padStart(2, '0');
        const year = dt.getFullYear();
        
        let hours = dt.getHours();
        const minutes = String(dt.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = String(hours).padStart(2, '0');
        
        tcat.push(`${month}/${day}/${year}\n${hours}:${minutes} ${ampm}`);
      } else {
          tcat.push(
              dt.toLocaleString('en-us', {
                
                month: 'short',
                day: 'numeric',
                year: 'numeric'
              })
          
            );
      }
    }

    setCategory(tcat);
    setLevel1(l1);
    setLevel2(l2);
    setLevel3(l3);
    setLevel4(l4);
    setLevel5(l5);
    setAvg(avg);
  }, [aprByLevel]);
  
  useEffect(() => {
    let end = Math.floor(new Date().getTime())
    let start = Math.floor(end - 604800000); // 1 week
    let data = {}
    if (workerChartTitle === '24h') {
      
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }

    }
    if (workerChartTitle === '7d') {
      
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
      
    }
    if (workerChartTitle === '30d') {
     
      let start = Math.floor(end - (2629743000) ); // 30.44 days 
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
    }
    if (workerChartTitle === '90d') {
      
      
      let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
      data = {
        "start": start,
        "end": end,
        "interval": "day"
      }
    }
    dispatch(getAprByLevel(data));
    
    
  }, []);




  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {}
    let end = Math.floor(new Date().getTime())
    if (e === '24h') {
      setTitle('24h');
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
      setChartInterval("24h")
    }
    if (e === '7d') {
      setTitle('7d');
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
      setChartInterval("7d")
    }
    if (e === '30d') {
      setTitle('30d');
      let start = Math.floor(end - (2629743000) ); // 30.44 days 
      data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
      setChartInterval("30d")
    }
    if (e === '90d') {
      setTitle('90d');
      
      let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
      data = {
        "start": start,
        "end": end,
        "interval": "day"
      }
      setChartInterval("90d")
    }
    dispatch(getAprByLevel(data));
    dispatch(setWorkerChartTitle(e))


  };

  return (
    <section className='leftChartCard pb-0'>
      <div className='row'>
      
        <h3 className='text-start col-10 ps-4' >APR (Worker Level)</h3>
        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>
        <div className='col-2 d-flex justify-content-end'>
        </div>
      </div>
      <div>
        {aprByLevelIsLoading === true?
        (
          <div class="position-absolute bottom-50 end-50">
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        ):("")}
        

        <div class="mt-3">
          <ReactEChart option={eChartsOption} />
        </div>
        
       
      </div>
    </section>
    
  );
  
};

export default AprByLevel;
